module.exports={
"label.account.name":`Vorname`,
"label.account.copy":`Bitte kopieren Sie den zufälligen Bestätigungscode in der EMail und klicken Sie auf den Link, um das Passwort im nächsten Schritt zu ändern.`,
"label.account.phone.length":`Die Handynummer muss aus mindestens 7 Zeichen bestehen.`,
"label.account.Start.trial":`GET STRTED`,
"label.account.employees1000":`1000  Mitarbeiter`,
"label.account.other.Ways":`Andere Anmeldemethoden`,
"label.account.emailsync.button.cancel":`Abbrechen`,
"label.account.rule":`Besteht aus 816 Ziffern und Buchstaben.`,
"label.account.password.update.success":`Passwort erfolgreich geändert.`,
"label.account.describe":`CloudCC konzentriert sich auf die digitale Personalisierung, analytische Intelligenz und globale Konnektivität und wird nach Bedarf zusammen mit der Geschäftsentwicklung von Unternehmen angepasst.`,
"label.account.enter.Emailbox":`Bitte geben Sie Ihre EMail Adresse ein.`,
"label.account.password":`Passwort`,
"label.account.problem":`Anmeldung fehlgeschlagen.`,
"label.account.Complete.reset":`EMail. Bitte geben Sie den Bestätigungscode ein, um das Zurücksetzen des Passworts abzuschließen.`,
"label.account.crm.used":`Von börsennotierten Unternehmen genutztes CRM`,
"label.account.report.loading":``,
"label.account.notcustomer":`Kein Konto?`,
"label.account.employees501":`5011000 Beschäftigte`,
"label.account.title.operationManager":`Betriebsleiter`,
"label.account.Preset":`Voreingestellte ProjektmanagementTools`,
"label.account.company":`Unternehmen`,
"label.account.come":`Willkommen bei der Cloudcc Customer Success Platform. Unsere neue LightningPlattform bietet Ihnen die schnellste und umfassendste Lösung, mit der Sie alles mit Ihrem Kunden im Mittelpunkt tun können.  `,
"label.account.input.password":`Bitte geben Sie Ihr Passwort ein.`,
"label.account.emailobject.emaildetail.button.back":`Zurück`,
"label.account.title.itManager":`ITLeiter`,
"label.account.Modified.successfully":`Passwortänderung erfolgreich. Bitte klicken Sie erneut auf Anmelden, um zur Anmeldeseite zurückzukehren.`,
"label.account.only11":`Bitte geben Sie mindestens 7 Ziffern ein.`,
"label.account.signin":`Anmeldung`,
"label.account.register":`Registrieren Sie sich für`,
"label.account.adopt":`Verifizierung bestanden!`,
"label.account.title.hrManager":`Marketing/HRManager`,
"label.account.input.usernames":`Bitte geben Sie Ihren Benutzernamen ein.`,
"label.account.only.Numbers":`Rufnummer kann nur Zahlen eingeben.`,
"label.account.title.salesManager":`Verkaufsleiter`,
"label.account.email":`EMail`,
"label.account.click":`Klicken Sie auf`,
"label.account.and":`und`,
"label.account.mobile":`Telefon`,
"label.account.Privacy.policy":`Datenschutzbestimmungen`,
"label.account.password.isnull":`Das Passwort darf nicht leer sein.`,
"label.account.employees101":`101500 Beschäftigte`,
"label.account.remember.name":`Benutzernamen merken`,
"label.account.Select.country":`Bitte wählen Sie Ihr Land/Ihre Region.`,
"label.account.distinguish":`Beim Passwort wird zwischen Groß und Kleinschreibung unterschieden.`,
"label.account.fillMobile":`Bitte geben Sie Ihre Telefonnummer ein.`,
"label.account.Preconfigured":`Voreingestellte Berichte und Dashboards`,
"label.account.Select.job":`Bitte wählen Sie Ihre Position.`,
"label.account.employees21":`21100 Beschäftigte`,
"label.account.username":`Benutzername`,
"label.account.Submit":`Einreichen`,
"label.account.confirm":`KontoBestätigung`,
"label.account.contact":`Kontakt`,
"label.account.country":`Land`,
"label.account.username.isnull":`Der Benutzername darf nicht leer sein.`,
"label.account.forget.password":`Passwort vergessen?`,
"label.account.Loading":`Laden`,
"label.account.goon":`Weiter`,
"label.account.Register.trial":`Registrieren Sie sich für eine kostenlose Testversion`,
"label.account.title.other":`Andere`,
"label.account.surname":`Nachname`,
"label.account.Fail":`Die Verifizierung ist fehlgeschlagen, bitte versuchen Sie es erneut.`,
"label.account.Terms.service":`Bedingungen der Dienstleistung`,
"label.account.Select.staff":`Bitte wählen Sie die Anzahl der Mitarbeiter.`,
"label.account.username.enter":`Bitte geben Sie Ihren Nachnamen ein.`,
"label.account.trial":`Kostenlose Testversion`,
"label.account.employees1":`120 Mitarbeiter`,
"label.account.Quick.registration":`oder schnellen Zugriff`,
"label.account.tabpage.ok":`Bestätigen Sie`,
"label.account.normal.reregister":`Erneut anmelden`,
"label.account.batchadd.newbatchpage.save.em":`Das EMailFormat ist nicht korrekt.`,
"label.account..user.title":`Berufsbezeichnung`,
"label.account.No.credit.card.required":`Keine Kreditkarte erforderlich.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Probendaten vorladen`,
"label.account.title.hobbies":`Hobbys`,
"label.account.platform":`Neue intelligente, mobile und digitale CRMPlattform`,
"label.account.sent":`EMail gesendet`,
"label.account.Confirm.password":`Bitte bestätigen Sie Ihr Passwort.`,
"label.account.fill":`Bitte alles ausfüllen.`,
"label.account.user.resetpw":`Passwort zurücksetzen`,
"label.account.Spell":`Ziehen Sie den Schieberegler, um das Rätsel zu vervollständigen.`,
"label.account.identifying.code":`Bitte geben Sie den Verifizierungscode ein.`,
"label.account.staff":`Mitarbeiter`,
"label.account.welcome":`Willkommen bei CloudCC`,
"label.account.online.guidance":`OnlineBeratung und Schulung`,
"label.account.login.CloudCC":`Anmeldung bei CloudCC`,
"label.account.Company.name":`Bitte geben Sie Ihren Firmennamen ein.`,
"label.account.title.generalManager":`Generaldirektor`,
"label.account.enter.user":`Um Ihr Passwort zurückzusetzen, geben Sie bitte Ihren CloudCCBenutzernamen ein.`,
"label.account.sent.code":`Der Verifizierungscode wurde gesendet an`,
"label.account.pw.error.text":`Die beiden von Ihnen eingegebenen Passwörter stimmen nicht überein.`,
"label.account.title.customerManager":`Leiter des Kundendienstes`,
"label.account.label.captcha":`Verifizierungscode`,
"label.account.Trial.days":`Registrieren Sie sich für eine kostenlose 30TageTestversion`,
"label.account.please.enter.name":`Bitte geben Sie Ihren Vornamen ein.`,
"label.account.inspect":`Wenn Sie die BestätigungsEMail mit dem Verifizierungscode nicht erhalten, überprüfen Sie bitte Ihr SpamPostfach.`,
"label.account.User.rule":`Der Benutzername wird in Form einer EMailAdresse angegeben.`,
"label.account.go.Login":`Sie haben bereits ein Konto?`,
"label.account.Reading.consent":`Ich akzeptiere die`,
"label.account.Change.Password2":`Passwort ändern`,
"label.account.Loginby":`Anmeldung durch`,
"label.account.apply.partner":`Bewerben Sie sich als CloudCCPartner`,
"label.account.MobileLogin":`Mobil`,
"label.account.Registration":``,
"label.account.Italy":`Italien`,
"label.account.view.details":`Detail ansehen`,
"label.account.accept":`Bitte lesen und akzeptieren Sie die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie`,
"label.account.China.Mainland":`Chinesisches Festland`,
"label.account.NewPassword":`Bitte geben Sie das neue Passwort ein`,
"label.account.Mobile.not.empty":`Die Handynummer darf nicht leer sein`,
"label.account.Please.select":`Bitte wählen Sie`,
"label.account.market.introduce":`Eine einheitliche Plattform für Marketingkanäle hilft Ihnen, mehr Leads zu gewinnen`,
"label.account.IOS":`Scannen, um iOSApp herunterzuladen`,
"label.account.failsend":`Senden fehlgeschlagen`,
"label.account.UpperLowerlAphanumeric.character":`Groß und Kleinbuchstaben, Zahlen und Symbole sind erforderlich`,
"label.account.New.Password1":`Neues Passwort`,
"label.account.succSend":`Es wurde eine EMail an Sie gesendet. Bitte kopieren Sie den Bestätigungscode in der EMail und setzen Sie das Passwort zurück.`,
"label.account.Account.mobile":`Konto oder Handynummer`,
"label.account.Login.consent":`Mit der Anmeldung erklären Sie sich einverstanden mit`,
"label.account.fillAreaCode":`Bitte geben Sie die Vorwahl ein`,
"label.account.active.login":``,
"label.account.jp":`Japan`,
"label.account.partner.success1":`Sie haben sich erfolgreich als CloudCCPartner registriert.`,
"label.account.Reset":`Zurücksetzen`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Bitte geben Sie das alte Passwort ein`,
"label.account.Germany":`Deutschland`,
"label.account.title.content":`Melden Sie sich für CloudCC | 30 Tage kostenloser Test an  Testen Sie jetzt!`,
"label.account.Taiwan":`Taiwan, China`,
"label.account.China.Station":`China`,
"label.account.sale.introduce":`Vertriebsautomatisierung hilft Ihnen, mehr Geschäfte zu machen`,
"label.account.Back":`Vorheriger Schritt`,
"label.account.market":`MarketingWolke`,
"label.account.section.company":`Details zum Unternehmen`,
"label.account.check.email":`unser Kundenbetreuer wird sich in Kürze bei Ihnen melden, bitte prüfen Sie rechtzeitig Ihre EMail`,
"label.account.characters":`5 Zeichen`,
"label.account.International":`International`,
"label.account.britain":`Vereinigtes Königreich`,
"label.account.reg.fail.msg":`Anmeldung fehlgeschlagen. Bitte versuchen Sie es später noch einmal.`,
"label.account.success.reg":`Sie haben sich erfolgreich registriert`,
"label.account.company.url":`Website`,
"label.account.CannotBeEmpty":`Kann nicht leer sein`,
"label.account.phone.note":``,
"label.account.second":`{0} Sekunden später`,
"label.account.Change.Password":`Ihr Passwort ändern`,
"label.account.Netherlands":`Niederlande`,
"label.account.pleaseAgree":`Bitte akzeptieren Sie die Nutzungsbedingungen und die Datenschutzrichtlinien und stimmen Sie ihnen zu.`,
"label.account.copyright":`CloudCC Inc. Alle Rechte vorbehalten`,
"label.account.letter":`1 Buchstabe`,
"label.account.remember":`Jetzt erinnere ich mich`,
"label.account.accountNum":`Konto`,
"label.account.code.not.empty":`Das Captcha darf nicht leer sein`,
"label.account.Spain":`Spanien`,
"label.account.Alphanumeric":`Eine Mischung aus Buchstaben und Zahlen ist erforderlich`,
"label.account.obtain":`Erwerben Sie`,
"label.account.company.txt":`Bitte fügen Sie Ihren Namen hinzu, wenn Sie kein eingetragenes Unternehmen haben.`,
"label.account.con.browser":``,
"label.account.Android":`Scannen, um AndroidApp herunterzuladen`,
"label.account.title":`Kostenlose Testversion von CloudCC  CloudCC.com`,
"label.account.partner.success2":`Wir werden uns in Kürze bei Ihnen melden.`,
"label.account.Macao":`Macau, China`,
"label.account.Brazil":`Brasilien`,
"label.account.service.introduce":`Nahtloser Serviceprozess verbessert die Effektivität der Reaktion`,
"label.account.codeFailure":`Ungültiger Verifizierungscode`,
"label.account.AccountLogin":`Konto`,
"label.account.pswComplexReqRules":`Die Mindestlänge des Passworts ist {0}, und {1}`,
"label.account.UpperLowerlAphanumeric":`Groß und Kleinbuchstaben und Zahlen sind erforderlich`,
"label.account.active.username":``,
"label.account.rememberPassword":`Passwort merken`,
"label.account.HongKong":`Honkong, China`,
"label.account.oldPass":`Altes Passwort`,
"label.account.Data.storage":`Ihre Daten werden im {0} Datenzentrum gespeichert.`,
"label.account.number":`1 Nummer`,
"label.account.Poland":`Polen`,
"label.account.service":`ServiceWolke`,
"label.account.Incorrect.format":`Falsches Format oder falsche Zahl, bitte neu eingeben`,
"label.account.remember.status":`Erinnern Sie sich an mich`,
"label.account.sale":`Vertriebswolke`,
"label.account.No.account.binding":`Kein Konto ist gebunden. Bitte kontaktieren Sie den Administrator`,
"label.account.Russia":`Russland`,
"label.account.France":`Frankreich`,
"label.account.partner.content":`CloudCC ist ein globales Unternehmen, das jetzt Partner sucht, um Lösungen für verschiedene Branchen zu entwickeln. Kommen Sie und nehmen Sie an unserem Partnerprogramm teil!`,
"label.account.reg.phone.already":`Diese Organisation existiert bereits für dieses Telefon. Melden Sie sich an oder verwenden Sie ein anderes Telefon, um sich anzumelden.`,
"label.account.Confirm":`Neues Passwort bestätigen`,
"label.account.VerificationLogin":`Code überprüfen`,
"label.account.USA":`Vereinigte Staaten`,
"label.account.mobile.num":`Bitte geben Sie die Handynummer ein`,
"label.account.MinLength":`Die Mindestlänge des Passworts beträgt`,
"label.account.change.your.password":`Bitte ändern Sie das Passwort jetzt aus Sicherheitsgründen`,
"label.account.new.password":`Geben Sie ein neues Passwort für {0} ein. Achten Sie darauf, dass es mindestens enthalten ist:`,
"label.account.website.enter":`Bitte geben Sie die Adresse der offiziellen Website ein`,
"label.account.pswComplexIsZero":``
}